<template>
  <div class="columns">
    <div class="column col-9 col-lg-12">
      <div class="teaser">
        <h1 v-html="$t('positioning.headline')" />
        <p>{{ $t('positioning.text_1') }}</p>
      </div>
    </div>
    <div class="column col-12">
      <p>
        <img src="../../assets/images/aboutus/nm_aboutus_group_small.png" class="positioning__image--small">
        {{ $t('positioning.text_2') }}
      </p>
    </div>
    <div class="column col-12 col-sm-12">
      <img src="../../assets/images/aboutus/nm_aboutus_group.jpg" class="positioning__image">
    </div>
    <div class="column column--full-width-bg">
      <div class="column col-12">
        <h4>{{ $t('positioning.subline') }}</h4>
        <ul class="list list--tagged">
          <li class="list__item" v-for="(obj, index) in $t('positioning.industries')" :key="index">{{obj}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Positioning',
}
</script>
<style lang="scss" scoped>
.column--image {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.positioning {
  &__image {
    @media (min-width: $size-lg) {
      margin-top: -5rem;
    }

    margin-bottom: 11vw;
    margin-top: 1rem;
    max-width: 100%;

    &--small {
      @media (min-width: $size-md) {
        shape-outside: margin-box;
        margin: 5.5rem -10rem 2rem 2rem;
        float: right;
        width: 50vw;
        max-width: 34rem;
      }

      object-fit: contain;
      max-width: 100%;
      margin: 1rem 0 2rem;
    }
  }
}
  .column--full-width-bg {
    &::before {
      background-color: $color-white;
    }
  }

  .list--tagged {
    display: flex;
    flex-wrap: wrap;
    font-size: .9rem;
    letter-spacing: .82px;
    margin-bottom: 4rem;

    .list__item {
      background-color: $color-primary-50;
      padding: 0.7em 1.5em;
      margin-right: .65em;
      margin-top: .65em;
    }
  }
</style>
